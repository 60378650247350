@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,1024;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,1024;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footmain{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.foter{
    background-color: #4285F4;
    padding: 0px;
    overflow: hidden;
    width:100vw;
}
.lefa{
    float: right;
}
.social-links{
    display: inline-flex;
}
.social-links a{
    
    padding:7px 20px 5px 20px ;
}
.dwheart{
    font-size: 20px;
    font-family: "Montserrat",sans-serif;
    font-weight: 700;
    padding-bottom: 10px;
}
.quick-links{

    color: #4285F4;
    display: block;
    
    justify-content: center;
    margin: auto;
    padding-bottom: 4px;
}
.quick-links a{
    font-family: 'Montserrat',sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: white;
    padding:0px 1%;
    text-decoration: none;;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(20% - 50px);
}
.gdsc{
    background-color: whitesmoke;
    font-size: 12px;
}
@media (max-width:610px){
    .quick-links{
        color: #4285F4;
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        grid-gap: 1px;
        justify-content: center;
        margin: auto;
        padding-bottom: 4px;
    }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
 .main {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
} 
.Table-view{
  padding-bottom: 10px;
}
.table {
  width: 45vw;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  height: 530px;
  color: #878787;
  margin-bottom: 10px;
  background-color: transparent;
}
.table .thead {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0px 5px 6px #00000029;
  margin-bottom: 10px;
  font-weight: 650;
}
.table .tbody {
  overflow-y: auto;
  background-color: transparent;
}
.table .tbody .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  margin-bottom: 5px;
  align-items: center;
  border-radius: 6px;
  font-size: 15px;
  box-shadow: 0px 5px 6px #00000029;
  font-weight: medium;
  min-height:35px;
}
p {
  min-width: 10vw;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.track {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 1vw;
}
::-webkit-scrollbar {
  width: 0px;
}
.search {
  height: 20px;
  padding: 20px 5vh;
  display: flex;

  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
  margin-bottom: 1vh;
  font-size: 15px;
  border: none;
  font-style: italic;
  font-weight: 430;
  color: #878787;
}
.search:active {
  border: none;
}



@media (max-width: 1024px) {
  .table {
    width: 90vw;
    font-size: 30px;
    height: 540px;
    /* padding-bottom: 30px; */
  }
  .table .thead {
    font-size: 44%;
    padding: 12px 4vw;
    margin-bottom: 7px;
  }

  .table .tbody .row {
    min-height: 30px;
    padding: 6px 6vw;
    margin-bottom: 7px;
  }
  .track {
    margin-right: 1vw;
  }
  .track p {
    margin: 0 1vw;
  }
  .search {
    font-size: 15px;
    margin-bottom: 3vw;
  }
} 
.mainh {
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100vw;
  
}
.bodybg {
  background: url(/static/media/webbg.191d9171.png) no-repeat   fixed;
  background-size: cover;
}
.header{              
  padding-bottom: 40px;
}
#gdsc{
  float: left;
  display: inline;
}
#gdsc img {
  padding-left: 20px;
  padding-top: 10px;
  width: 400px;
  
  
}
#gdsc span {
  padding-top: 10px;
  
 
}
#span2 {
  padding-top: 140px;
  font-size: 1.4cm;
  font-family: "Times New Roman", Times, serif;
  padding-left: 170px;
}
#span1 {
  font-size: 2.1cm;
  font-family: "Times New Roman", Times, serif;
  color: rgb(97, 89, 89);
  padding-top: 10px;
}
#discord {
  display: inline;
  align-items: center;
  padding: 10px;
  float: right;
  
  background-color: #7289DA;
  border-radius: 30px;
  margin-right: 40px;
  margin-top: 15px;

  
}
#discord a p{
  font-size:15px;
  align-items: center;
  display: inline;
}
#discord a,#viewEvents a {
  display: inline;
  text-decoration: none;
  color: white;
  padding: 20px 20px;
  
 
}
#viewEvents {
  float: right;
  font-size: 20px;
  text-align-last: center;
  background-color: #4285F4;
  padding: 10px;
  border-radius: 30px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

#cloud_logo {
  border: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
  /*margin-right: 25cm;*/
  width: 45%;
  background-color: white;
  opacity: 1;
  color: #707070;
  box-shadow: rgba(0, 0, 0, 0.35) 10px 15px 115px;
}
#cloud_logo img {
  display: inline;
  margin-top: 2%;
  padding-left: 10px;
  padding-right: 10px;
}
#cloud_logo p {
  font-size: 15px;
}
#cloud_logo span {
    font-size: 20px;
    font-style: italic;
  }
#title{
  text-align: left;
  padding-top: 1%;
  padding-left: 32px;
  font-weight: 550;
  padding-left: 1%;
}
.content{
  font-size: 5px;
  padding-left: 1%;
  text-align: left;
}
#yellow{
    color:#FBBC04;
    
}
#red{
    color: #EA4335;
}
#blue{
    color: #4285F4;
}
#green{
    color:#34A853;
}
@media (max-width:1366px){
  #cloud_logo {
    border: 10px;
    border-radius: 20px;
    margin-bottom: 20px;
    /*margin-right: 25cm;*/
    width: 50%;
    background-color: white;
    opacity: 1;
    color: #707070;
    box-shadow: rgba(0, 0, 0, 0.35) 10px 15px 115px;
  }
  #cloud_logo img {
    display: inline;
    margin-top: 3%;
    padding-left: 10px;
    padding-right: 10px;
  }
  #cloud_logo p {
    font-size: 15px;
  }
  #cloud_logo span {
      font-size: 15px;
      font-style: italic;
    }
  #title{
    text-align: left;
    padding-top: 1%;
    padding-left: 32px;
    font-weight: 550;
    padding-left: 1%;
  }
  .content{
    padding-left: 1%;
    text-align: left;
  }
  #viewEvents {
    float: right;
    font-size: 15px;
    text-align-last: center;
    background-color: #4285F4;
    padding: 8px 1px;
    border-radius: 30px;
    margin-top: 0px;
    margin-right: 11px;
    margin-bottom: 10px;
  }
  
}
@media (max-width:1024px) {
  .bodybg {
    background: url(/static/media/mobilebg.16c76e5b.png) no-repeat   fixed;
    background-size: cover;
  }
  #gdsc img {
    padding-left: 20px;
    padding-top: 10px;
    width: 200px;
    
  }
  #discord {
    display: inline;
    align-items: center;
    
    float: right;
    padding: 7px 0px;
    background-color: #7289DA;
    border-radius: 30px;
    margin-right: 3%;
    margin-top: 6px;
  
    
  }
  #discord a p{
    padding-bottom: 50%;
    font-size:13px;
    align-items: center;
    display: inline;
  }
  #discord a,#viewEvents a {
    display: inline;
    text-decoration: none;
    color: white;
    padding: 10px 10px;
    
   
  }
  #viewEvents {
    float: right;
    font-size: 15px;
    text-align-last: center;
    background-color: #4285F4;
    padding: 7px;
    border-radius: 30px;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #cloud_logo {
    border: 10px;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    /*margin-right: 25cm;*/
    width: 90vw;
    background-color: white;
    opacity: 1;
    color: #707070;
    box-shadow: rgba(0, 0, 0, 0.35) 10px 15px 115px;
  }
  #cloud_logo img {
    width: 50px;
    display: inline;
    margin-top: 3%;
    padding-left: 10px;
    padding-right: 10px;
  }
  #cloud_logo p {
    font-size: 10px;
  }
  #cloud_logo span {
      font-size: 20px;
      font-style: italic;
    }
  #title{
    text-align: left;
    padding-top: 3%;
    padding-left: 32px;
    font-weight: 550;
    padding-left: 1%;
  }
  .content{

    padding: 0px 10px 10px 10px;
    text-align: left;
  }
  
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .premain{
    display: flex;
    justify-content: center;
    padding: 30px;
    width: 100vw;
    min-height: 100vh;
    align-items:center ;
    flex-direction: column;
    text-align: center;
    background-color: #f5f5f5;
  }
  .preloader {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .logo {
    margin-bottom: 30px;
  }
  .logo img {
    width: 80%;
    -webkit-animation: init 0.1s 0s linear forwards ;
            animation: init 0.1s 0s linear forwards ;
  }
  
  .loading {
    background-color: transparent;
    position: relative;
    justify-content: center;
    height: 75px;
    width: 300px;
    align-items: center;
    padding: 10px;
  }
  .loading .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 3px;
    position: absolute;
  }
  .blue {
    background-color: #4285f4;
    left: 20%;
    bottom: 10%;
    -webkit-animation: top 0.3s 0.1s ease-out forwards,down 0.3s 0.4s ease-in forwards,
    shake 0.3s 0.7s linear,shake2 0.3s 1.0s ease-in;
            animation: top 0.3s 0.1s ease-out forwards,down 0.3s 0.4s ease-in forwards,
    shake 0.3s 0.7s linear,shake2 0.3s 1.0s ease-in;
  }
  .yellow {
    background-color: #fbbc04;
    left: 35%;
    bottom: 10%;
    -webkit-animation: top 0.3s 0.2s ease-out forwards,down 0.3s 0.5s ease-in forwards,
    shake 0.3s 0.8s linear,shake2 0.3s 1.1s ease-in;
            animation: top 0.3s 0.2s ease-out forwards,down 0.3s 0.5s ease-in forwards,
    shake 0.3s 0.8s linear,shake2 0.3s 1.1s ease-in;
  }
  .green {
    background-color: #0f9d58;
    left: 50%;
    bottom: 10%;
    -webkit-animation: top 0.3s 0.4s ease-out forwards,down 0.3s 0.7s ease-in forwards,
    shake 0.3s 1.0s linear,shake2 0.3s 1.3s ease-in;
            animation: top 0.3s 0.4s ease-out forwards,down 0.3s 0.7s ease-in forwards,
    shake 0.3s 1.0s linear,shake2 0.3s 1.3s ease-in;
  }
  .red {
    background-color: #ea4335;
    left: 65%;
    bottom: 10%;
    -webkit-animation: top 0.3s 0.5s ease-out forwards,down 0.3s 0.8s ease-in forwards,
    shake 0.3s 1.1s linear,shake2 0.2s 1.4s ease-in;
            animation: top 0.3s 0.5s ease-out forwards,down 0.3s 0.8s ease-in forwards,
    shake 0.3s 1.1s linear,shake2 0.2s 1.4s ease-in;
  }
  @-webkit-keyframes init{
    100%{
      width: 80%;
    }
  }
  @keyframes init{
    100%{
      width: 80%;
    }
  }

  @-webkit-keyframes top {
    100% {
      bottom: 80%;
    }
  }

  @keyframes top {
    100% {
      bottom: 80%;
    }
  }
  @-webkit-keyframes down {
    100% {
      bottom: 10%;
    }
  }
  @keyframes down {
    100% {
      bottom: 10%;
    }
  }
  @-webkit-keyframes shake {
    0%,
    100% {
      bottom: 10%;
    }
    50% {
      bottom: 30%;
    }
  }
  @keyframes shake {
    0%,
    100% {
      bottom: 10%;
    }
    50% {
      bottom: 30%;
    }
  }
  @-webkit-keyframes shake2{
    0%,
    100% {
      bottom: 10%;
    }
    50% {
      bottom: 15%;
    }
  }
  @keyframes shake2{
    0%,
    100% {
      bottom: 10%;
    }
    50% {
      bottom: 15%;
    }
  }
 
