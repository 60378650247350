@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,1024;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,1024;1,700;1,800;1,900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
 .main {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
} 
.Table-view{
  padding-bottom: 10px;
}
.table {
  width: 45vw;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  height: 530px;
  color: #878787;
  margin-bottom: 10px;
  background-color: transparent;
}
.table .thead {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0px 5px 6px #00000029;
  margin-bottom: 10px;
  font-weight: 650;
}
.table .tbody {
  overflow-y: auto;
  background-color: transparent;
}
.table .tbody .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  margin-bottom: 5px;
  align-items: center;
  border-radius: 6px;
  font-size: 15px;
  box-shadow: 0px 5px 6px #00000029;
  font-weight: medium;
  min-height:35px;
}
p {
  min-width: 10vw;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.track {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 1vw;
}
::-webkit-scrollbar {
  width: 0px;
}
.search {
  height: 20px;
  padding: 20px 5vh;
  display: flex;

  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
  margin-bottom: 1vh;
  font-size: 15px;
  border: none;
  font-style: italic;
  font-weight: 430;
  color: #878787;
}
.search:active {
  border: none;
}



@media (max-width: 1024px) {
  .table {
    width: 90vw;
    font-size: 30px;
    height: 540px;
    /* padding-bottom: 30px; */
  }
  .table .thead {
    font-size: 44%;
    padding: 12px 4vw;
    margin-bottom: 7px;
  }

  .table .tbody .row {
    min-height: 30px;
    padding: 6px 6vw;
    margin-bottom: 7px;
  }
  .track {
    margin-right: 1vw;
  }
  .track p {
    margin: 0 1vw;
  }
  .search {
    font-size: 15px;
    margin-bottom: 3vw;
  }
} 