.mainh {
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100vw;
  
}
.bodybg {
  background: url(resources/webbg.png) no-repeat   fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.header{              
  padding-bottom: 40px;
}
#gdsc{
  float: left;
  display: inline;
}
#gdsc img {
  padding-left: 20px;
  padding-top: 10px;
  width: 400px;
  
  
}
#gdsc span {
  padding-top: 10px;
  
 
}
#span2 {
  padding-top: 140px;
  font-size: 1.4cm;
  font-family: "Times New Roman", Times, serif;
  padding-left: 170px;
}
#span1 {
  font-size: 2.1cm;
  font-family: "Times New Roman", Times, serif;
  color: rgb(97, 89, 89);
  padding-top: 10px;
}
#discord {
  display: inline;
  align-items: center;
  padding: 10px;
  float: right;
  
  background-color: #7289DA;
  border-radius: 30px;
  margin-right: 40px;
  margin-top: 15px;

  
}
#discord a p{
  font-size:15px;
  align-items: center;
  display: inline;
}
#discord a,#viewEvents a {
  display: inline;
  text-decoration: none;
  color: white;
  padding: 20px 20px;
  
 
}
#viewEvents {
  float: right;
  font-size: 20px;
  text-align-last: center;
  background-color: #4285F4;
  padding: 10px;
  border-radius: 30px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

#cloud_logo {
  border: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
  /*margin-right: 25cm;*/
  width: 45%;
  background-color: white;
  opacity: 1;
  color: #707070;
  box-shadow: rgba(0, 0, 0, 0.35) 10px 15px 115px;
}
#cloud_logo img {
  display: inline;
  margin-top: 2%;
  padding-left: 10px;
  padding-right: 10px;
}
#cloud_logo p {
  font-size: 15px;
}
#cloud_logo span {
    font-size: 20px;
    font-style: italic;
  }
#title{
  text-align: left;
  padding-top: 1%;
  padding-left: 32px;
  font-weight: 550;
  padding-left: 1%;
}
.content{
  font-size: 5px;
  padding-left: 1%;
  text-align: left;
}
#yellow{
    color:#FBBC04;
    
}
#red{
    color: #EA4335;
}
#blue{
    color: #4285F4;
}
#green{
    color:#34A853;
}
@media (max-width:1366px){
  #cloud_logo {
    border: 10px;
    border-radius: 20px;
    margin-bottom: 20px;
    /*margin-right: 25cm;*/
    width: 50%;
    background-color: white;
    opacity: 1;
    color: #707070;
    box-shadow: rgba(0, 0, 0, 0.35) 10px 15px 115px;
  }
  #cloud_logo img {
    display: inline;
    margin-top: 3%;
    padding-left: 10px;
    padding-right: 10px;
  }
  #cloud_logo p {
    font-size: 15px;
  }
  #cloud_logo span {
      font-size: 15px;
      font-style: italic;
    }
  #title{
    text-align: left;
    padding-top: 1%;
    padding-left: 32px;
    font-weight: 550;
    padding-left: 1%;
  }
  .content{
    padding-left: 1%;
    text-align: left;
  }
  #viewEvents {
    float: right;
    font-size: 15px;
    text-align-last: center;
    background-color: #4285F4;
    padding: 8px 1px;
    border-radius: 30px;
    margin-top: 0px;
    margin-right: 11px;
    margin-bottom: 10px;
  }
  
}
@media (max-width:1024px) {
  .bodybg {
    background: url(resources/mobilebg.png) no-repeat   fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #gdsc img {
    padding-left: 20px;
    padding-top: 10px;
    width: 200px;
    
  }
  #discord {
    display: inline;
    align-items: center;
    
    float: right;
    padding: 7px 0px;
    background-color: #7289DA;
    border-radius: 30px;
    margin-right: 3%;
    margin-top: 6px;
  
    
  }
  #discord a p{
    padding-bottom: 50%;
    font-size:13px;
    align-items: center;
    display: inline;
  }
  #discord a,#viewEvents a {
    display: inline;
    text-decoration: none;
    color: white;
    padding: 10px 10px;
    
   
  }
  #viewEvents {
    float: right;
    font-size: 15px;
    text-align-last: center;
    background-color: #4285F4;
    padding: 7px;
    border-radius: 30px;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #cloud_logo {
    border: 10px;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    /*margin-right: 25cm;*/
    width: 90vw;
    background-color: white;
    opacity: 1;
    color: #707070;
    box-shadow: rgba(0, 0, 0, 0.35) 10px 15px 115px;
  }
  #cloud_logo img {
    width: 50px;
    display: inline;
    margin-top: 3%;
    padding-left: 10px;
    padding-right: 10px;
  }
  #cloud_logo p {
    font-size: 10px;
  }
  #cloud_logo span {
      font-size: 20px;
      font-style: italic;
    }
  #title{
    text-align: left;
    padding-top: 3%;
    padding-left: 32px;
    font-weight: 550;
    padding-left: 1%;
  }
  .content{

    padding: 0px 10px 10px 10px;
    text-align: left;
  }
  
}